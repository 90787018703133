<template>
  <div>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             title="二级核心素养"
             :footer="null"
             width="1250rem">

      <div class="center_table">
        <div class="table_head">
          <div>
            核心素养
          </div>
          <!-- <div>频率</div> -->
          <div>
            操作
          </div>
        </div>
      </div>
      <div class="table_line"
           v-for="(item,index) in list"
           :key="index">
        <div class="two">
          <div>
            {{item.capability_name}}
          </div>
          <!-- <div>
            <img src="@/static/zhishidian/xx.jpg"
                 style="width:20rem;height:20rem"
                 v-for="(item,idx) in item.knowledge_point_star"
                 :key="idx" />
          </div> -->
          <div>
            <a-button type="primary"
                      @click="toPaper(item)">
              进入训练
            </a-button>
          </div>
          <span class="icon"
                :style="{'background':item.active?'#2196F3':'#D8D8D8'}"
                v-if="item.child">
            <span>
              <plus-outlined @click="change(index,true)"
                             v-if="item.active == false" />
              <minus-outlined @click="change(index,false)"
                              v-else />
            </span>
          </span>
        </div>
        
      </div>
    </a-modal>
  </div>
</template>


<script setup>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue'
const router = useRouter()
let dialogVisible = ref(false)
let list = ref([])
let subjectId = ref('')


const toPaper = (item) => {
  dialogVisible.value = false
  let form = {
    subject_id: subjectId.value,
    capability_id: item.capability_id
  }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?isCapability=1&form=' + JSON.stringify(form))
}

const change = (index, bool) => {
  list.value[index].active = bool
}

defineExpose({
  dialogVisible, list, subjectId
})
// export default {
//   data () {
//     return {
//       dialogVisible: false,
//       list: [],
//       subjectId: ''
//     }
//   },
//   methods: {
//     handleClose () {
//       this.dialogVisible = false
//     },
//     toPaper (item) {
//       this.handleClose()
//       let form = {
//         subject_id: this.subjectId,
//         knowledge_id: item.knowledge_id
//       }
//       window.sessionStorage.removeItem('testpaperCardIndex')
//       window.sessionStorage.removeItem('paper')
//       this.$router.push('/testpaper/paperinfo?isKnowledge=1&form=' + JSON.stringify(form))
//     },
//     change (index, bool) {
//       this.list[index].active = bool
//       this.$forceUpdate()
//     }
//   }
// }
</script>

<style lang="scss" scoped>
.table_head {
  height: 58rem;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24rem;
    color: #666660;
    &:nth-child(1) {
      flex: 3;
      text-indent: 70rem;
    }
    &:nth-child(2) {
      flex: 1;
      display: block;
      text-align: center;
    }
    &:nth-child(3) {
      flex: 1;
      text-align: center;
      display: block;
    }
  }
}
.table_line {
  background: white;
  border-bottom: 1px solid #eaeaea;
  font-size: 22rem;
  line-height: 58rem;
  .two {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    .ant-btn {
      font-size: 20rem;
      height: 40rem;
      border-radius: 5rem;
      padding: 0rem 12rem;
    }
    div {
      &:nth-child(1) {
        flex: 3;
        text-indent: 70rem;
        font-weight: bold;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 15rem;
        font-weight: bold;
        color: #ff6147;
        font-size: 14px;
        display: block;
        text-align: center;
      }
      &:nth-child(3) {
        flex: 1;
        text-align: center;
      }
    }
  }
  .three {
    display: flex;
    align-items: center;
    background: #f5faff;

    div {
      &:nth-child(1) {
        flex: 3;
        text-indent: 70px;
        color: #333333;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 15px;
        font-weight: bold;
        color: #ff6147;
        font-size: 14px;
      }
      &:nth-child(3) {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin-right: 30rem;
      }
    }
  }
}

::v-deep .el-icon-close {
  border: 1px solid #666666;
  border-radius: 50%;
}

.icon {
  text-indent: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #2196f3;
  color: white !important;
}
</style>
